<template>
  <header-component
    :is-transparent="transparentRoutesNames.includes(route)"
    :menu-active="menuActive"
    @toggle-menu="toggleMenu"
  />
  <div class="wrapper" :class="{ menuActive }">
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
    <footer-component />
    <footer-rights-component />
  </div>
  <PageLoader />
</template>

<script>
import FooterComponent from '@/shared/ui/layout/ui/FooterMenuComponent.vue';
import FooterRightsComponent from '@/shared/ui/layout/ui/FooterRightsComponent.vue';
import HeaderComponent from '@/shared/ui/layout/ui/HeaderComponent.vue';
import PageLoader from '@/shared/ui/PageLoader.vue';

export default {
  name: 'LandingLayout',
  components: {
    PageLoader,
    HeaderComponent,
    FooterComponent,
    FooterRightsComponent,
  },
  data() {
    return {
      menuActive: false,
    };
  },
  computed: {
    route() {
      return this.$route.name;
    },
    transparentRoutesNames() {
      return ['HomeView', 'SimulatorCaseView'];
    },
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fff;
  &.menuActive {
    height: 100vh;
    overflow: hidden;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="home">
    <slider-on-main-component />
    <popular-trainer-component />
    <about-us-component />
    <contact-us-component id="contact-us-section" />
    <!-- <info-switcher-component /> -->
  </div>
</template>

<script>
import ContactUsComponent from '@/widgets/contact-us/ui/ContactUsComponent.vue';
import PopularTrainerComponent from '@/widgets/popular-trainer/ui/PopularTrainerComponent.vue';
import SliderOnMainComponent from '@/widgets/slider-on-main/ui/SliderOnMainComponent.vue';
import AboutUsComponent from '@/widgets/about-us/ui/AboutUsComponent.vue';

export default {
  name: 'HomeView',
  components: {
    AboutUsComponent,
    SliderOnMainComponent,
    PopularTrainerComponent,
    ContactUsComponent,
  },
};
</script>

<style scoped></style>

<template>
  <div id="popular-trainers" class="popular-trainers">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t('projects') }}</h2>
        <h1>{{ $t('popular-trainers') }}</h1>
      </div>
      <div class="trainers-grid">
        <!-- item start-->
        <div v-for="item in applications.slice(0, sliceIndex)" :key="item.id">
          <router-link
            :to="{
              name: 'SimulatorCaseView',
              params: { locale: $route.params.locale, id: item.id },
            }"
            class="trainer-item"
            :style="{ 'background-image': 'url(' + item.baсkground + ') ' }"
          >
            <div class="trainer-item__header">
              <div class="trainer-item__tag-section">
                <div class="trainer-item__tag">
                  {{ item.category }}
                </div>
              </div>
            </div>
            <div class="trainer-item__footer">
              {{ item.title }}
            </div>
          </router-link>
        </div>
        <!-- item end -->
      </div>
      <div v-if="!isAllFetched" class="show-more">
        <button v-if="!loading" class="btn btn-common" @click="showMore">
          {{ $t('more-trainers') }}
        </button>
        <DiveLoader v-if="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import DiveLoader from '@/shared/ui/DiveLoader.vue';
import { mapActions, mapState } from 'pinia';
import { usePopularApplicationStore } from '@/app/store/popularApplicationStore';

export default {
  name: 'PopularTrainerComponent',
  components: { DiveLoader },
  data() {
    return {
      sliceIndex: 4,
    };
  },
  computed: {
    ...mapState(usePopularApplicationStore, ['applications', 'loading']),
    isAllFetched() {
      return this.sliceIndex >= this.applications.length;
    },
  },
  mounted() {
    this.getApplications();
  },
  methods: {
    ...mapActions(usePopularApplicationStore, ['getApplications']),
    showMore() {
      this.sliceIndex += this.sliceIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.popular-trainers {
  padding: 60px 0;
  .container {
    flex-direction: column;
  }
}
.section-title {
  @media screen and (max-width: 500px) {
    padding: 0 10px;
  }
  h2 {
    padding-bottom: 4px;
  }
  h1 {
    padding-top: 4px;
  }
}

.btn {
  width: fit-content;
  margin: 0 auto;
}
.trainers-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .trainer-item {
    text-decoration: none;
    position: relative;
    height: 587px;
    background-color: rgba(0, 25, 45, 0.6);
    cursor: pointer;
    border-radius: 10px;
    background-blend-mode: soft-light;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center center;
    background-size: cover;
    transition: background-color 0.3s cubic-bezier(0.88, 0.67, 0.17, 0.67);

    .trainer-item__tag-section {
      display: flex;
      padding: 40px;
      .trainer-item__tag {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
        text-align: center;
        padding: 2px 12px;
        mix-blend-mode: normal;
        border: 1px solid #a4a6b5;
        border-radius: 100px;
      }
    }
    .trainer-item__footer {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 27px;
      color: #ffffff;
      padding: 40px 32% 40px 40px;
      text-transform: uppercase;
    }
    &:after {
      opacity: 0;
      transition: opacity 0.3s cubic-bezier(0.17, 0.67, 0.88, 0.67);
    }
    &:hover {
      background-color: rgba(24, 24, 24, 1);
      transition: background-color 0.3s cubic-bezier(0.17, 0.67, 0.88, 0.67);
    }
    &:hover:after {
      content: '';
      position: absolute;
      opacity: 1;
      width: 114px;
      height: 114px;
      top: calc(50% - 114px / 2);
      left: calc(50% - 114px / 2);
      background: url(~@/assets/images/icons/plus-circle.svg);
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.88, 0.67);
    }
  }
}
.show-more {
  margin: 0 auto;
  margin-top: 50px;
}
</style>

<template>
  <header ref="header" class="header" :class="{ isTransparent, menuActive }">
    <div class="header__wrapper container">
      <div class="logo">
        <router-link
          :to="{
            name: 'HomeView',
            params: { locale: $route.params.locale },
          }"
          @click="menuActive && $emit('toggleMenu')"
        >
          <img
            src="../../../../assets/images/logo.png"
            alt="DIVE платформа"
            class="logo--full"
          />
        </router-link>
      </div>
      <div class="top-menu">
        <div
          class="top-menu__item search-button"
          :class="{ isTransparent: isTransparent && !menuActive }"
        >
          <router-link to="/">
            <img
              src="../../../../assets/images/icons/search-white.svg"
              alt="DIVE платформа"
            />
          </router-link>
        </div>
        <div
          class="top-menu__item account-button"
          :class="{ isTransparent: isTransparent && !menuActive }"
        >
          <router-link
            :to="{
              name: 'LoginView',
              params: { locale: $route.params.locale },
            }"
            @click="menuActive && $emit('toggleMenu')"
          >
            <img
              src="../../../../assets/images/icons/user-white.svg"
              alt="DIVE платформа"
            />
          </router-link>
        </div>
        <div class="top-menu__item language-button">
          <language-choice-component
            :is-transparent="isTransparent && !menuActive"
            :active="menuActive"
          />
        </div>
        <hamburger-component
          :active="menuActive"
          :is-transparent="isTransparent && !menuActive"
          @toggle-menu="$emit('toggleMenu')"
        />
      </div>
    </div>
    <div v-show="showMenu" class="menu__wrapper">
      <transition :css="false" @before-enter="beforeEnter">
        <div v-show="showMenu" class="navbar container">
          <div class="menu-title">{{ $t('menu.menu') }}</div>
          <div class="menu-items">
            <div class="navbar-nav">
              <router-link
                :to="{
                  name: 'SimulatorsView',
                  params: { locale: $route.params.locale },
                }"
                class="menu-item"
                @click="$emit('toggleMenu')"
              >
                {{ $t('menu.trainers') }}
              </router-link>
              <router-link
                :to="{
                  name: 'ServicesView',
                  params: { locale: $route.params.locale },
                }"
                class="menu-item"
                @click="$emit('toggleMenu')"
              >
                {{ $t('menu.services') }}
              </router-link>
              <router-link
                :to="{
                  name: 'MethodistsView',
                  params: { locale: $route.params.locale },
                }"
                class="menu-item"
                @click="$emit('toggleMenu')"
              >
                {{ $t('menu.methodists') }}
              </router-link>
              <router-link
                :to="{
                  name: 'ContactsView',
                  params: { locale: $route.params.locale },
                }"
                class="menu-item"
                @click="$emit('toggleMenu')"
              >
                {{ $t('menu.contacts') }}
              </router-link>
              <router-link
                :to="{
                  name: 'DevelopersView',
                  params: { locale: $route.params.locale },
                }"
                class="menu-item"
                @click="$emit('toggleMenu')"
              >
                {{ $t('menu.4developers') }}
              </router-link>
            </div>
          </div>

          <div class="menu-bottom__middle">
            <div class="menu-bottom__contacts">
              <!--
              <div class="menu-bottom__contacts__phone">
                <div class="menu-bottom-text phone-text">{{ $t('menu.call') }}:</div>
                <a href="tel:89539121200" class="menu-link">8 953 912 - 12 - 00</a>
              </div>
              -->
              <div class="menu-bottom__contacts__email">
                <div class="menu-bottom-text email-text">
                  {{ $t('menu.write-email') }}:
                </div>
                <a href="mailto:dive@tpu.ru" class="menu-link">dive@tpu.ru</a>
              </div>
            </div>
            <div class="menu-bottom__contacts__contact-us">
              <a
                href="#contact-us-section"
                class="btn btn-common"
                @click="$emit('toggleMenu')"
                >{{ $t('make-request') }}</a
              >
            </div>
          </div>
          <div class="menu-bottom__footer">
            <!--            <router-link-->
            <!--              :to="{-->
            <!--                name: 'TermsView',-->
            <!--                params: { locale: $route.params.locale },-->
            <!--              }"-->
            <!--              @click="$emit('toggleMenu')"-->
            <!--              >{{ $t('menu.terms') }}</router-link-->
            <!--            >-->
            <!--            <router-link-->
            <!--              :to="{-->
            <!--                name: 'PrivacyView',-->
            <!--                params: { locale: $route.params.locale },-->
            <!--              }"-->
            <!--              @click="$emit('toggleMenu')"-->
            <!--              >{{ $t('menu.privacy') }}</router-link-->
            <!--            >-->
            <router-link
              :to="{
                name: 'CookiesView',
                params: { locale: $route.params.locale },
              }"
              @click="$emit('toggleMenu')"
              >{{ $t('menu.cookies') }}</router-link
            >
            <router-link
              :to="{
                name: 'HelpView',
                params: { locale: $route.params.locale },
              }"
              @click="$emit('toggleMenu')"
              >{{ $t('menu.help') }}</router-link
            >
            <router-link
              :to="{
                name: 'FAQView',
                params: { locale: $route.params.locale },
              }"
              @click="$emit('toggleMenu')"
              >{{ $t('menu.FAQ') }}</router-link
            >
            <router-link
              :to="{
                name: 'SupportView',
                params: { locale: $route.params.locale },
              }"
              @click="$emit('toggleMenu')"
              >{{ $t('menu.support') }}</router-link
            >
            <SocialNetworks type="outlined" />
          </div>
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
import HamburgerComponent from '@/shared/ui/HamburgerComponent.vue';
import LanguageChoiceComponent from '@/shared/ui/LanguageChoiceComponent.vue';
import { gsap } from 'gsap';
import SocialNetworks from '@/shared/ui/SocialNetworks.vue';
export default {
  name: 'HeaderComponent',
  components: { SocialNetworks, LanguageChoiceComponent, HamburgerComponent },
  props: {
    isTransparent: Boolean,
    menuActive: Boolean,
  },
  emits: ['toggleMenu'],
  data() {
    return {
      showMenu: false,
    };
  },
  watch: {
    menuActive(newVal) {
      if (newVal) {
        this.showMenu = true;
        gsap.fromTo(
          '.menu__wrapper',
          {
            opacity: 0,
            yPercent: -100,
          },
          {
            opacity: 1,
            yPercent: 0,
            duration: 0.5,
          },
        );

        gsap.fromTo(
          '.menu-item',
          {
            opacity: 0,
            xPercent: -100,
          },
          {
            opacity: 1,
            delay: 0.25,
            xPercent: 0,
            stagger: 0.1,
          },
        );
      } else {
        gsap.fromTo(
          '.menu__wrapper',
          {
            opacity: 1,
            yPercent: 0,
          },
          {
            opacity: 0,
            duration: 0.5,
            yPercent: -100,
          },
        );
        setTimeout(() => {
          this.showMenu = false;
        }, 700);
      }
    },
  },
  mounted() {
    gsap.set('.menu__wrapper', {
      opacity: 0,
      yPercent: -100,
    });
  },
  methods: {
    beforeEnter() {},
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  z-index: 100;
  transition: all 500ms ease;

  &.isTransparent {
    position: absolute;
  }
  &.menuActive {
    position: absolute;
    background-color: #fff;
    transition: background-color 500ms ease;
    height: 100vh;
  }
  &__wrapper {
    z-index: 100;
    width: 100%;
    max-height: 100px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      max-height: 48px;
      height: 48px;
      margin: auto 0;

      &--full {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  .top-menu {
    display: flex;
    align-items: center;

    .top-menu__item {
      margin-right: 30px;
      &.isTransparent {
        img {
          filter: invert(0);
        }
      }
      img {
        filter: invert(1);
        transition: filter 400ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      &:hover {
        img {
          filter: invert(0.5);
          transition: filter 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }
      }

      .divider {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.1px;
        color: #a4a6b5;
      }
    }
  }
}
.menu__wrapper {
  width: 100%;
  border-top: 1px solid #ebebeb;
  height: calc(100vh - 100px);
  box-sizing: border-box;
  @media only screen and (max-width: 650px) {
    height: 150vh;
  }
  .navbar {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    .menu-title {
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 52px;
      line-height: 61px;
      letter-spacing: -0.1px;
      color: #222e37;
    }

    .menu-items {
      .navbar-nav {
        display: flex;
        flex-direction: column;
        .menu-item {
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
          color: #222e37;
          display: grid;
          margin: 25px 0 17px;
          text-decoration: none;
          padding-bottom: 8px;
          width: min-content;
          border-bottom: 2px solid transparent;
          transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          &:hover {
            color: #0a7cff;
            border-bottom: 2px solid #0a7cff;
            transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          }
        }
      }
    }

    .menu-bottom {
      &__middle {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 650px) {
          flex-direction: column;
          gap: 20px;
          align-items: center;
        }
      }
      &__contacts {
        display: flex;
        &__email {
          //margin-left: 86px;
          @media only screen and (max-width: 650px) {
            text-align: center;
          }
        }
        .menu-bottom-text {
          font-family: 'Proxima Nova', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 27px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #222e37;
        }
        .menu-link {
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 130%;

          font-feature-settings:
            'pnum' on,
            'lnum' on;
          color: #0a7cff;
        }
      }
      &__footer {
        display: grid;
        grid-template-columns: repeat(5, auto);
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 1024px) {
          grid-template-columns: repeat(2, auto);
          gap: 8px;
        }
        @media only screen and (max-width: 650px) {
          grid-template-columns: repeat(2, auto);
          row-gap: 8px;
          text-align: center;
          column-gap: 20px;
          align-items: center;
          justify-content: center;
          justify-items: center;
        }
        a {
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.1px;
          text-decoration-line: underline;
          color: #222e37;
          opacity: 0.7;
        }
      }
    }
  }
}

.global-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(1, 1, 1, 0.77);
  z-index: 50;
}

@media only screen and (min-width: 992px) {
}

@media only screen and (max-width: 991px) {
  .navbar {
    z-index: 100;
  }

  #navbarNav {
  }
}
</style>

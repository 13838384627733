import { defineStore } from 'pinia';
import { useSliderStore } from '@/app/store/sliderStore';
import { usePopularApplicationStore } from '@/app/store/popularApplicationStore';
import { useTagsStore } from '@/app/store/tagsStore';
import { useApplicationsStore } from '@/app/store/applicationsStore';
import { useMethodistsStore } from '@/app/store/methodistsStore';

export const useLangStore = defineStore('langStore', {
  state: () => ({
    lang: 'ru',
  }),
  actions: {
    setLang(lang) {
      if (lang !== this.getLang()) {
        useSliderStore().reset();
        usePopularApplicationStore().reset();
        useTagsStore().reset();
        useApplicationsStore().reset();
        useMethodistsStore().reset();
        this.lang = lang;
      }
    },
    getLang() {
      return this.lang;
    },
  },
});

<template>
  <div class="footer-bottom">
    <div class="container">
      <div class="footer-bottom__copyrights">
        <div class="footer-bottom__copyright">
          &copy; D.I.V.E., 2022-{{ year }}
        </div>
      </div>
      <div class="footer-bottom__links-privacy">
        <!--        <a href="#" class="footer__link">{{ $t('menu.terms') }}</a>-->
        <!--        <a href="#" class="footer__link">{{ $t('menu.privacy') }}</a>-->
        <a href="#" class="footer__link">{{ $t('menu.cookies') }}</a>
        <a href="#" class="footer__link">{{ $t('menu.help') }}</a>
        <a href="#" class="footer__link">{{ $t('menu.FAQ') }}</a>
        <a href="#" class="footer__link">{{ $t('menu.support') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterRightsComponent',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-bottom {
  font-family: 'Raleway', sans-serif;
  background: #181818;
  display: flex;
  border-top: 1px solid rgba(164, 166, 181, 1);

  width: 100%;
  margin: auto;
  overflow: hidden;
  .container {
    flex-direction: row;
    @media screen and (max-width: 500px) {
      padding: 0 10px;
    }
  }

  &__copyrights {
    padding-top: 24px;
    padding-bottom: 32px;

    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.1px;
    color: #ffffff;
    mix-blend-mode: normal;
  }
  &__links-privacy {
    padding-top: 27px;
    padding-bottom: 34px;
    display: flex;
    align-items: flex-end;
    text-align: right;
    flex-wrap: wrap;
    a {
      margin-left: 32px;
      text-decoration: underline;
    }
  }
}
</style>

<template>
  <div class="social-networks">
    <a
      href="https://vk.com/video/@divevrtpu"
      class="social__link"
      :class="{
        'vk-icon': type === 'filled',
        'vk-icon-lines': type === 'outlined',
        'vk-icon-monocolor': type === 'monocolor',
      }"
      target="_blank"
    ></a>
    <a
      href="https://t.me/vrdive"
      class="social__link"
      :class="{
        'telegram-icon': type === 'filled',
        'telegram-icon-monocolor': type === 'monocolor',
        'telegram-icon-lines': type === 'outlined',
      }"
      target="_blank"
    ></a>
    <a
      href="https://www.youtube.com/@VR_D.I.V.E./featured"
      class="social__link youtube-icon"
      :class="{
        'youtube-icon': type === 'filled',
        'youtube-icon-monocolor': type === 'monocolor',
        'youtube-icon-lines': type === 'outlined',
      }"
      target="_blank"
    ></a>
  </div>
</template>

<script>
export default {
  name: 'SocialNetworks',
  props: {
    type: {
      type: String,
      default: 'filled',
    },
  },
};
</script>

<style scoped lang="scss">
.social-networks {
  display: flex;
  align-items: center;
  column-gap: 18px;
}
</style>

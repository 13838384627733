<template>
  <div class="section-title-wrapper">
    <h1 class="section-title">
      {{ title }}
    </h1>
    <span class="section-subtitle">
      {{ subtitle }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    title: String,
    subtitle: String,
  },
};
</script>

<style scoped lang="scss">
.section-title-wrapper {
  position: relative;
  .section-subtitle {
    color: #0a7cff;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: -20px;
  }
}
</style>
